.app {
  display: flex;
  padding: 20px;
}

.workspace {
  display: flex;
  gap: 20px;
}

.toolbar {
  width: 100px;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.toolbar-item {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: move;
  text-align: center;
}

.grid {
  border: 1px solid #ccc;
  background: #fff;
}

.svg-output {
  padding: 20px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: auto;
  max-height: 600px;
  width: 400px;
}

.svg-output pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  font-family: monospace;
}

.svg-output code {
  color: #333;
}

.rotation-handle {
  cursor: pointer;
  fill: yellow;
}

.rotation-handle:hover {
  fill: #ffeb3b;
}

.toolbar-item svg {
  margin-bottom: 5px;
}

.toolbar-item span {
  display: block;
  font-size: 12px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 15px;
}

.modal-content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #4CAF50;
  color: white;
}

.modal-buttons button:last-child {
  background-color: #f5f5f5;
}

.toolbar-section {
  margin-bottom: 20px;
}

.import-button {
  display: block;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.import-button:hover {
  background-color: #45a049;
}

.svg-output-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.svg-output-header h3 {
  margin: 0;
}

.copy-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: #45a049;
}

.copy-button:active {
  background-color: #3d8b40;
}

.context-menu {
  position: fixed;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 5px;
}

.context-menu button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  color: #333;
}

.context-menu button:hover {
  background-color: #f5f5f5;
}

/* Add a selected state style for elements */
.selected-element {
  outline: 2px dashed #4CAF50;
  outline-offset: 2px;
}

.svg-output-buttons {
  display: flex;
  gap: 10px;
}

.action-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #45a049;
}

.action-button:active {
  background-color: #3d8b40;
}

.download-button {
  background-color: #2196F3;
}

.download-button:hover {
  background-color: #1976D2;
}

.download-button:active {
  background-color: #1565C0;
}